import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import capitalize from "lodash/fp/capitalize";
import flow from "lodash/fp/flow";
import forEach from "lodash/fp/forEach";
import join from "lodash/fp/join";
import keys from "lodash/fp/keys";
import map from "lodash/fp/map";
import pick from "lodash/fp/pick";
import classNames from "classnames";
import moment from "moment";
import api from "utils/api";
import { generateFirstName, generateLastName, serializeFirebaseUser } from "utils/helpers";
import { BookingSourceContext } from "contexts/BookingSourceContext";
import {
  corporateCodeForReservations,
  containsCorporateRateReservation,
  membershipRuleForCode,
} from "utils/membershipRules";
import createGuestDetailsForm from "components/Checkout/utils/createGuestDetailsForm";
import generateBookingData from "utils/generateBookingData";
import { performEmailSignIn, performEmailSignUp } from "utils/firebase/auth/email";
import { calculateTotalToPay } from "utils/bookingTotals";
import validator from "validator";
import Routes from "config/routes";
import Heading from "components/Heading";
import Basket from "components/Basket";
import BookingSummary from "components/BookingSummary";
import Navbar from "components/Navbar";
import PaymentWidget from "components/PaymentWidget";
import LoadingDialog from "components/LoadingDialog";
import TerminalPaymentWidget from "components/TerminalPaymentWidget";
import TerminalPaymentInProgressDialog from "components/TerminalPaymentInProgressDialog";
import AuthCTA from "components/AuthCTA";
import LoadingSpinner from "components/LoadingSpinner";
import StepBreadcrumb from "components/StepBreadcrumb";
import PhoneInput from "components/PhoneInput";
import MaskedTextField from "components/MaskedTextField";

import { useSearchParams } from "hooks";
import { selectRoomReservations } from "selectors/booking";
import { setPaymentError } from "../../features/payment/paymentSlice";
import { setFirebaseToken, signInUser, signOutUser } from "features/user/userSlice";
import { bookingSuccess } from "features/booking/bookingSlice";
import { trackBeginCheckoutEvent, trackPurchaseEvent } from "utils/analytics";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { STEPS } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    padding: theme.spacing(2),
    margin: "1em auto",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.componentBackground1,
  },
  mainContent: {
    margin: "1em auto",
    width: theme.contentSize.mobilePageWidth,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: theme.contentSize.pageWidth,
      maxWidth: theme.contentSize.maxPageWidth,
    },
  },
  splitContent: {
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "row",
    },
  },
  basket: {
    marginLeft: "20px",
    flexBasis: 0,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      display: "none",
    },
  },
  justifyCenter: {
    justifyContent: "center",
  },
  checkoutDetailsCard: {
    width: "100%",
    marginBottom: "20px",
    backgroundColor: theme.palette.background.default,
  },

  mobileBookingSummary: {
    marginBottom: theme.spacing(2),
  },
  stepper: {
    paddingTop: 0,
    background: "none",

    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      paddingLeft: 0,
    },
  },
  stepContainer: {
    padding: `${theme.spacing(3)}px`,
    marginBottom: `${theme.spacing(3)}px`,
  },
  authStepContainer: {
    border: "none",
  },

  stepperEditIcon: {
    color: theme.palette.primary.main,
    width: "22px",
  },
  paymentWidget: {
    paddingLeft: "0px",
    marginLeft: "0px",
  },
  mobileStep: {
    paddingRight: "0px",
    marginRight: "0px",
  },
  authContainer: {
    background: theme.palette.background.componentBackground1,
  },
  termsBox: {
    margin: 0,
    padding: `${theme.spacing(1)}px 0px`,
    width: "100%",
    alignItems: "flex-start",
  },
  continueAsGuestButton: {
    float: "right",
    marginTop: `${theme.spacing(3)}px`,
  },
  textButton: {
    "&:hover": { backgroundColor: "inherit" },
  },

  checkboxOrRadioContainer: {
    margin: 0,
    padding: `${theme.spacing(1)}px 0px`,
    alignItems: "flex-start",
  },
  checkbox: {
    padding: `0px ${theme.spacing(1)}px`,
  },
  validationFailed: {
    backgroundColor: theme.palette.error.background,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: "4px",
  },
  makeBookingContainer: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.primary.border}`,
    borderRadius: "16px",
  },
  makeBookingButton: {
    width: "100%",
  },
  hidden: {
    display: "none",
  },
  inputField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 20000,
      backgroundColor: theme.palette.background.default,
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "20px",
      backgroundColor: theme.palette.background.default,
    },
  },
}));

const Checkout = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const desktopUpScreen = useMediaQuery(theme.breakpoints.up("desktop"));
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));
  const appTheme = useSelector((state) => state.booking.appTheme);

  const roomReservations = useSelector(selectRoomReservations);

  const searchParams = useSearchParams();
  const { bookingSource } = useContext(BookingSourceContext);

  const captureAdditionalGuestDetails =
    process.env.REACT_APP_CAPTURE_ADDITIONAL_GUEST_DETAILS === "true";

  const [password, setPassword] = useState("");
  const shouldShowAuth = bookingSource.authEnabled && !(appTheme && appTheme === "soho");
  const [isGuestCheckout, setIsGuestCheckout] = useState(!shouldShowAuth);

  const [bookerDetails, setBookerDetails] = useState({
    firstName: "",
    middleInitial: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    comments: "",
    membershipNumber: "",
    subscribeOffers: false,
  });
  const [displayName, setDisplayName] = useState("");
  const [isBookerMainGuest, setIsBookerMainGuest] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [firstNameInputError, setFirstNameInputError] = useState(false);
  const [lastNameInputError, setLastNameInputError] = useState(false);
  const [emailInputError, setEmailInputError] = useState(false);
  const [phoneInputError, setPhoneInputError] = useState(false);
  const [membershipNumberInputError, setMembershipNumberInputError] = useState(false);
  const [termsValidationError, setTermsValidationError] = useState(false);
  const [reasonForStay, setReasonForStay] = useState("leisure");

  const [bookingInProgress, setBookingInProgress] = useState(false);

  const [loading, setLoading] = useState(true);
  const [membershipRule, setMembershipRule] = useState(null);

  const isUserIdentified = Boolean(isGuestCheckout || props.authUser);

  // Stores the booking id, payment timeout and payment data needed to check status
  const [terminalPaymentData, setTerminalPaymentData] = useState(null);

  const validateCheckoutForm = () => {
    if (!validateInputNotBlank()) {
      props.onNotifOpen("Please fill all guest details", {
        variant: "error",
      });

      return false;
    } else if (!validateTermsAccepted()) {
      return false;
    }

    return true;
  };

  function formatDisplayName(firstName, lastName) {
    return flow(
      map((val) => capitalize(val)),
      join(" ")
    )([firstName, lastName]);
  }

  const additionalGuestInfo = createGuestDetailsForm(
    roomReservations,
    captureAdditionalGuestDetails
  );

  const initialGuestInfoState = additionalGuestInfo.reduce((acc, { guestDetails }) => {
    guestDetails.forEach(({ guestNum, ...details }) => {
      acc[`guest_${guestNum}`] = details;
    });
    return acc;
  }, {});

  const [additionalGuestDetails, setAdditionalGuestDetails] = useState(initialGuestInfoState);

  const validateInputNotBlank = () => {
    const validFirstName = !validator.isEmpty(bookerDetails.firstName);
    const validLastName = !validator.isEmpty(bookerDetails.lastName);
    const validEmail = validator.isEmail(bookerDetails.email);
    const validPhoneNumber = validator.isMobilePhone(bookerDetails.phoneNumber || "");

    let validMembershipNumber = true;
    if (membershipRule) {
      validMembershipNumber =
        validMembershipNumber && !validator.isEmpty(bookerDetails.membershipNumber);

      if (membershipRule.mask) {
        // If theres a mask, also ensure there are no _ in the output
        validMembershipNumber =
          validMembershipNumber && !validator.contains(bookerDetails.membershipNumber, "_");
      }
    }

    setFirstNameInputError(!validFirstName);
    setLastNameInputError(!validLastName);
    setEmailInputError(!validEmail);
    setPhoneInputError(!validPhoneNumber);
    setMembershipNumberInputError(!validMembershipNumber);
    return (
      validFirstName && validLastName && validEmail && validPhoneNumber && validMembershipNumber
    );
  };

  const validateAdditionalGuestDetails = () => {
    // Check all additional guest information has been populate
    let hasFilledAllDetails = true;
    const updatedGuestDetails = {};

    flow(
      keys,
      forEach((key) => {
        // apply validation error to only first and last name fields
        const guest = additionalGuestDetails[key];

        const firstGuest = guest.guestIdx === 0;

        const updatedGuest = {
          ...guest,
          firstName: {
            ...guest.firstName,
            error: validator.isEmpty(guest.firstName.value),
          },
          lastName: {
            ...guest.lastName,
            error: validator.isEmpty(guest.lastName.value),
          },
          email: {
            ...guest.email,
            error: firstGuest && validator.isEmpty(guest.email.value),
          },
        };
        updatedGuestDetails[key] = updatedGuest;
        // update hasFilledAllDetails based on whether any of the TextFields have an error
        hasFilledAllDetails =
          hasFilledAllDetails &&
          !validator.isEmpty(guest.firstName.value) &&
          !validator.isEmpty(guest.lastName.value);

        if (firstGuest) {
          hasFilledAllDetails = hasFilledAllDetails && !validator.isEmpty(guest.email.value);
        }
      })
    )(additionalGuestDetails);

    // Popup with the warning at the bottom
    if (!hasFilledAllDetails) {
      props.onNotifOpen("Please complete all guest details", {
        variant: "error",
      });
    }

    // update additionalGuestDetails object with error for use by input field
    setAdditionalGuestDetails(updatedGuestDetails);
    return hasFilledAllDetails;
  };

  const validateTermsAccepted = () => {
    if (!termsAccepted) {
      props.onNotifOpen("Please accept terms and conditions to continue", {
        variant: "error",
      });

      setTermsValidationError(true);
    }
    return termsAccepted;
  };

  const handleAuthUserChange = (userData) => {
    props.signInUser(serializeFirebaseUser(userData));
  };

  const handleBookerDetailsChange = (key, value) => {
    setBookerDetails((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleReasonForStay = (event) => {
    setReasonForStay(event.target.value);
  };

  const handleSkip = () => {
    if (activeStep === 2) {
      // clear personalise your stay from booking
      setReasonForStay(null);
    }
    handleNext();
  };

  const handleAdditionalGuestDetailsChange = (guestNum, label, value) => {
    setAdditionalGuestDetails((prevState) => ({
      ...prevState,
      [guestNum]: {
        ...(prevState[guestNum] || {}), //copy over previous guest information
        [label]: {
          ...(prevState[guestNum]?.[label] || {}), //copy over the prev label from initial state
          value,
        },
      },
    }));
  };

  const onBookButtonClicked = async () => {
    if (!validateCheckoutForm()) {
      console.log("Checkout form validation failed");
      return;
    }

    await handleCheckout(null);
  };

  const triggerTerminalPayment = async () => {
    if (!validateCheckoutForm()) {
      console.log("Checkout form validation failed");
      return;
    }

    // Show the booking on progress dialog
    setBookingInProgress(true);

    // We first need to make the booking, and then trigger a terminal payment
    let bookingId = null;
    try {
      bookingId = await makeBooking(null);

      console.log(`Booking created - ${bookingId}`);

      // Now trigger a payment via the terminal
      const payByTerminalResponse = await api.payByTerminal(
        bookingId,
        searchParams["paymentTerminalId"]
      );

      // Save the trigger payment response - the terminal dialog will poll status
      setTerminalPaymentData({
        propertyId: props.propertyId,
        bookingId: bookingId,
        paymentTimeout: moment().add(60, "seconds"),
        paymentId: payByTerminalResponse.data.payment_id,
        folioId: payByTerminalResponse.data.folio_id,
      });
    } catch (error) {
      console.log(`Error making booking: ${error}`);

      await handleTerminalPaymentFailed(
        props.propertyId,
        bookingId,
        `Your booking could not be completed. You will not be charged.`
      );
    }
  };

  const handleTerminalPaymentFailed = async (propertyId, bookingId, errorMessage) => {
    console.log(`Cancelling booking with id: ${bookingId}`);

    const paymentId = terminalPaymentData.paymentId;
    const folioId = terminalPaymentData.folioId;

    setTerminalPaymentData(null);

    // Cancel any payments made
    if (paymentId && folioId) {
      await api.cancelTerminalPayment(paymentId, folioId).catch((e) => {
        // Catch any errors & continue with canceling booking
        console.log(`Failed to cancel payment: ${e}`);
      });
    }

    // Cancel the booking
    if (bookingId) {
      await api.cancelBooking(propertyId, bookingId);
      setBookingInProgress(false);
    }

    if (errorMessage) {
      props.onNotifOpen(errorMessage, { variant: "error", autoHideDuration: null });
    }
  };

  const makeBooking = async (paymentResponse) => {
    // Combine all reservations for booking
    const bookingReservations = [...props.roomReservations, ...props.parkingReservations];
    const data = generateBookingData({
      bookingReservations: bookingReservations,
      bookerDetails: bookerDetails,
      paymentResponse: paymentResponse,
      giftCard: props.giftCard,
      propertyId: props.propertyId,
      additionalGuests: additionalGuestDetails,
      travelPurpose: reasonForStay,
    });

    const response = await api.makeBooking(data);

    trackPurchaseEvent(
      bookingReservations,
      props.promoCode || props.corporateCode,
      props.propertyId,
      response.data.id,
      paymentResponse ? paymentResponse.additionalData.paymentMethod : "giftcard"
    );

    if (password && !props.authUser) {
      handleSignUp();
    }

    return response.data.id;
  };

  const handleCheckout = async (paymentResponse) => {
    // Show the booking on progress dialog
    setBookingInProgress(true);

    try {
      const bookingId = await makeBooking(paymentResponse);
      handleBookingSuccess(bookingId);
    } catch (error) {
      console.log("Error making booking: ", error);

      const errorMessage = error.response?.data?.messages[0] || "Unknown error";
      const message = `Your booking could not be completed. You will not be charged. ${errorMessage}`;

      if (props.totalToPay > 0) {
        // Let payment widget handle the error
        props.setPaymentError(message);
      } else {
        // No payment widget - present the error here
        props.onNotifOpen(message, { variant: "error" });
      }

      setBookingInProgress(false);
    }
  };

  const handleBookingSuccess = async (bookingId) => {
    // on success:
    // save guest details (for the restaurant reservations)
    // clear booked reservations State,
    // and redirect to confirmation page

    // This clears the reservations and persists guest details for the restaurant reservations
    props.bookingSuccess({ bookingId, bookerDetails });

    props.history.push(Routes.CONFIRM_STAY);
  };

  const handleReservationRemoved = () => {
    props.onNotifOpen("Reservation successfully removed", {
      variant: "success",
    });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.currentTarget.value);
  };

  const signIntoFirebase = async () => {
    try {
      const { firebaseToken, authUser } = await performEmailSignIn(bookerDetails.email, password);

      handleAuthUserChange(authUser);

      return firebaseToken;
    } catch (e) {
      console.error(e);
      props.onNotifOpen(e.message, { variant: "error" });
    }
  };

  const fetchProfile = (firebaseToken) => {
    api
      .getProfile(firebaseToken)
      .then((res) => {
        props.onProfileChange(res.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleSignIn = async () => {
    const firebaseToken = await signIntoFirebase();

    if (firebaseToken) {
      props.setFirebaseToken(firebaseToken);
      fetchProfile(firebaseToken);
    }
  };

  const handleSignUp = async () => {
    try {
      await performEmailSignUp(
        bookerDetails.email,
        password,
        bookerDetails.firstName,
        bookerDetails.lastName
      );

      handleSignIn();
    } catch (e) {
      console.error(e);

      if (e.code === "auth/email-already-in-use") {
        props.onNotifOpen(
          "Your email address is already associated to an existing profile. Please sign in to manage your reservations.",
          { variant: "warning" }
        );
      }
    }
  };

  const getFirstName = () => {
    // Attempts to use profile's first name. Falls back to
    // auth first name if profile unavailable (e.g unverified & not loaded)
    if (props.profile && props.profile.firstName) {
      return props.profile.firstName;
    } else if (props.authUser) {
      return generateFirstName(props.authUser.displayName);
    } else {
      return "";
    }
  };

  const getLastName = () => {
    // Attempts to use profile's first name. Falls back to
    // auth first name if profile unavailable (e.g unverified & not loaded)
    if (props.profile && props.profile.lastName) {
      return props.profile.lastName;
    } else if (props.authUser) {
      return generateLastName(props.authUser.displayName);
    } else {
      return "";
    }
  };

  useEffect(() => {
    // Redirect to home if user has no reservations left in the cart,
    // Skip if user is in middle of booking where the cart is emptied
    if (props.roomReservations.length === 0 && props.bookingConfirmationId == null) {
      props.onNotifOpen("You dont have any rooms in your basket.", {
        variant: "error",
      });
      props.history.push(Routes.PLAN_STAY);
    } else if (props.bookingConfirmationId == null) {
      const bookingReservations = [...props.roomReservations, ...props.parkingReservations];

      trackBeginCheckoutEvent(bookingReservations, props.promoCode || props.corporateCode);
    }
  }, [
    props.onNotifOpen,
    props.roomReservations,
    props.parkingReservations,
    props.bookingConfirmationId,
  ]);

  useEffect(() => {
    if (!props.authUser) return;

    setBookerDetails((prevState) => ({
      ...prevState,
      firstName: getFirstName(),
      lastName: getLastName(),
      email: props.authUser ? props.authUser.email : "",
      phoneNumber: props.profile ? props.profile.phone : "",
    }));
    setDisplayName(formatDisplayName(getFirstName(), getLastName()));
    // Reset fields as the user may have tried to go to next step as guest previously
    validateInputNotBlank();
  }, [props.authUser, props.profile]);

  useEffect(() => {
    if (!props.authUser) return;
    validateInputNotBlank();
  }, [bookerDetails.phoneNumber]);

  useEffect(() => {
    async function fetchMembershipRule() {
      try {
        const response = await api.getMembershipRules();
        const membershipRules = response.data;

        // Calculate and store the rule that applies to the basket (if any)
        const corporateCode = corporateCodeForReservations(props.roomReservations);
        const rule = membershipRuleForCode(membershipRules, corporateCode);
        setMembershipRule(rule);
      } catch (e) {
        // Log the error but fail silently. We still want user to be able to make the booking
        console.error("Failed to fetch membership rules.");
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    // If theres a reservation with a corporate code, fetch the restrictions
    if (containsCorporateRateReservation(props.roomReservations)) {
      fetchMembershipRule();
    } else {
      // No loading to do
      setLoading(false);
    }
  }, []);

  function populatePrimaryGuestWithBookerDetails() {
    flow(
      pick(["firstName", "lastName", "email"]),
      keys,
      // Update the field inputs of Guest 1 in GuestDetails
      map((fieldLabel) => {
        handleAdditionalGuestDetailsChange("guest_1", fieldLabel, bookerDetails[fieldLabel]);
      })
    )(bookerDetails);
  }

  function clearPrimaryGuestDetails() {
    flow(
      pick(["firstName", "lastName", "email"]),
      keys,
      // Clear the field inputs of Guest 1 in GuestDetails
      map((fieldLabel) => {
        handleAdditionalGuestDetailsChange("guest_1", fieldLabel, "");
      })
    )(bookerDetails);
  }

  useEffect(() => {
    if (isBookerMainGuest) {
      // Populate Guest 1 Details
      populatePrimaryGuestWithBookerDetails();
    } else {
      // Clear Guest 1 Details
      clearPrimaryGuestDetails();
    }
  }, [isBookerMainGuest, bookerDetails]);

  useEffect(() => {
    if (!shouldShowAuth) {
      // Sign user out if initially logged in when reaching here during soho
      props.signOutUser();
    }
  }, []);

  const toggleTermsAcceptance = (event) => {
    setTermsAccepted(event.target.checked);
    setTermsValidationError(false);
  };

  const handleSignOut = () => {
    props.signOutUser();
  };

  const showMembershipField = () => {
    // Show the membership field if we managed to load a membership rule for the basket
    return props.corporateCode && membershipRule;
  };

  const checkoutSteps = () => {
    let steps = [
      {
        label: "Your details",
        content: (
          <Paper
            className={
              !props.authUser && !isGuestCheckout && shouldShowAuth
                ? classes.authStepContainer
                : classes.stepContainer
            }>
            {!props.authUser && !isGuestCheckout && shouldShowAuth ? (
              <Paper mb={3}>
                <Box className={classes.authContainer}>
                  <AuthCTA
                    logIn={true}
                    authUser={props.authUser}
                    onAuthUserChange={props.onAuthUserChange}
                    onNotifOpen={props.onNotifOpen}
                    splitLinks={!tabletUpScreen}
                    onlySignIn={true}
                    loginText="Log in to book faster"
                    headingTextVariant="subtitle1"
                  />
                </Box>
                <Button
                  className={classNames(classes.continueAsGuestButton, classes.textButton)}
                  variant="text"
                  color="primary"
                  onClick={() => setIsGuestCheckout(true)}>
                  Continue as guest
                </Button>
              </Paper>
            ) : null}

            {isUserIdentified || !shouldShowAuth ? (
              <>
                <Box mb={1}>
                  <Box>
                    {props.authUser && (
                      <Box>
                        <Typography variant="subtitle1" color="textPrimary">
                          You are logged in as:
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          {displayName}
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          {bookerDetails.email}
                        </Typography>
                        <Button
                          variant="text"
                          className={classes.textButton}
                          color="primary"
                          onClick={handleSignOut}
                          style={{ marginBottom: "20px", paddingLeft: "0px" }}>
                          Log out
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <Typography variant="subtitle1" color="textPrimary">
                      Enter your details
                    </Typography>
                    {props.authUser
                      ? null
                      : shouldShowAuth && (
                          <Button
                            variant="text"
                            color="primary"
                            className={classes.textButton}
                            onClick={() => setIsGuestCheckout(false)}>
                            Login
                          </Button>
                        )}
                  </Box>
                </Box>
                <Box mb={1}>
                  <Typography variant="body2" color="textPrimary">
                    * Required fields
                  </Typography>
                </Box>
                {!props.authUser && (
                  <Grid container justifyContent="space-between">
                    <Grid item xs={desktopUpScreen ? 6 : 12}>
                      <Box mb={2} px={1}>
                        <TextField
                          fullWidth
                          className={classes.inputField}
                          size="small"
                          name="given-name"
                          autoComplete="given-name"
                          label="First name"
                          type="text"
                          required
                          variant="outlined"
                          value={bookerDetails.firstName}
                          onChange={(e) => {
                            handleBookerDetailsChange("firstName", e.currentTarget.value);
                          }}
                          error={firstNameInputError}
                          helperText={firstNameInputError ? "This field is required." : ""}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={desktopUpScreen ? 6 : 12}>
                      <Box mb={2} px={1}>
                        <TextField
                          fullWidth
                          className={classes.inputField}
                          size="small"
                          name="family-name"
                          autoComplete="family-name"
                          label="Last name"
                          type="text"
                          required
                          variant="outlined"
                          value={bookerDetails.lastName}
                          onChange={(e) => {
                            handleBookerDetailsChange("lastName", e.currentTarget.value);
                          }}
                          error={lastNameInputError}
                          helperText={lastNameInputError ? "This field is required." : ""}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                )}
                <Grid container justifyContent="space-between">
                  {!props.authUser && (
                    <Grid item xs={desktopUpScreen ? 6 : 12}>
                      <Box mb={2} px={1}>
                        <TextField
                          fullWidth
                          className={classes.inputField}
                          size="small"
                          name="email"
                          autoComplete="email"
                          label="Email"
                          type="text"
                          required
                          variant="outlined"
                          value={bookerDetails.email}
                          onChange={(e) => {
                            handleBookerDetailsChange("email", e.currentTarget.value);
                          }}
                          error={emailInputError}
                          helperText={emailInputError ? "This field is required." : ""}
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={desktopUpScreen ? 6 : 12}>
                    <Box mb={2} px={props.authUser ? 0 : 1}>
                      <PhoneInput
                        defaultCountry="GB"
                        placeholder="e.g +44 7911 123456"
                        countryOptionsOrder={["GB", "|"]}
                        value={bookerDetails.phoneNumber}
                        onChange={(value) => {
                          handleBookerDetailsChange("phoneNumber", value);
                        }}
                        error={phoneInputError}
                        errorHelperText="Please enter a valid phone number."
                      />
                    </Box>
                  </Grid>
                </Grid>
                <FormControlLabel
                  className={classNames(classes.checkboxOrRadioContainer)}
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      checked={isBookerMainGuest}
                      onChange={(e) => {
                        setIsBookerMainGuest(e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body1" color="textPrimary">
                      I am the main guest
                    </Typography>
                  }
                />
              </>
            ) : null}

            {isGuestCheckout && shouldShowAuth ? (
              <Grid item xs={desktopUpScreen ? 6 : 12}>
                <Box mb={2} mt={1} px={1}>
                  <Box mb={1}>
                    <Typography variant="subtitle1" color="textPrimary">
                      Enter a password to create an account
                    </Typography>
                  </Box>

                  <TextField
                    fullWidth
                    className={classes.inputField}
                    size="small"
                    name="new-password"
                    autoComplete="new-password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={handlePasswordChange}
                    helperText="Optional (6 characters minimum)"
                  />
                </Box>
              </Grid>
            ) : null}
            {showMembershipField() ? (
              <Box mt={4} mb={3}>
                <Typography variant="h6" color="textPrimary">
                  {membershipRule.company} Membership
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Please enter your membership number below.
                  <br />
                  You will need to show proof of your membership number at check in.
                </Typography>

                <Box mt={2} mb={3}>
                  <MaskedTextField
                    mask={membershipRule.mask}
                    maskChar="_"
                    value={bookerDetails.membershipNumber}
                    onChange={(e) => {
                      handleBookerDetailsChange("membershipNumber", e.currentTarget.value);
                    }}
                    name="membership_number"
                    label="Membership Number"
                    type="text"
                    className={classes.inputField}
                    size="small"
                    variant="outlined"
                    textFieldProps={{
                      fullWidth: true,
                      required: true,
                      error: membershipNumberInputError,
                      helperText: membershipNumberInputError
                        ? "Please enter a valid membership number."
                        : membershipRule.hint,
                      // If theres no mask, theres a bug in the input mask library
                      // which causes the input label to overlay the text
                      InputLabelProps: membershipRule.mask ? null : { shrink: true },
                    }}
                  />
                </Box>
              </Box>
            ) : null}
          </Paper>
        ),
      },
    ];

    if (captureAdditionalGuestDetails || !isBookerMainGuest) {
      steps.push({
        label: "Guest Details",
        content: (
          <Paper className={classes.stepContainer}>
            {additionalGuestInfo.map(({ roomNum, guestDetails }, roomIdx) => {
              return (
                <Box key={roomNum}>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    style={{ marginBottom: "10px" }}>
                    {`Room ${roomNum}`}
                  </Typography>
                  <Box>
                    {guestDetails.map(({ guestNum, firstName, lastName, email }, guestIdx) => {
                      return (
                        <Box key={guestNum}>
                          {guestDetails.length > 1 && (
                            <Typography variant="body2" color="textPrimary">
                              {`Guest ${guestNum}`}
                            </Typography>
                          )}
                          <Grid
                            container
                            style={{ marginTop: "20px" }}
                            justifyContent="space-between">
                            <Grid item xs={desktopUpScreen ? 6 : 12}>
                              <Box mb={2} pr={1}>
                                <TextField
                                  fullWidth
                                  className={classes.inputField}
                                  size="small"
                                  label={firstName.label}
                                  type="text"
                                  name={`given-name-${guestNum}`}
                                  autoComplete="given-name"
                                  required
                                  disabled={roomIdx === 0 && guestIdx === 0 && isBookerMainGuest}
                                  variant="outlined"
                                  value={
                                    additionalGuestDetails[`guest_${guestNum}`].firstName?.value
                                  }
                                  error={
                                    additionalGuestDetails[`guest_${guestNum}`].firstName?.error
                                  }
                                  helper={
                                    additionalGuestDetails[`guest_${guestNum}`].firstName?.error
                                      ? "Required"
                                      : ""
                                  }
                                  onChange={(e) => {
                                    handleAdditionalGuestDetailsChange(
                                      `guest_${guestNum}`,
                                      "firstName",
                                      e.currentTarget.value
                                    );
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={desktopUpScreen ? 6 : 12}>
                              <Box mb={2} pr={1}>
                                <TextField
                                  fullWidth
                                  className={classes.inputField}
                                  size="small"
                                  label={lastName.label}
                                  type="text"
                                  name={`family-name-${guestNum}`}
                                  disabled={roomIdx === 0 && guestIdx === 0 && isBookerMainGuest}
                                  autoComplete="family-name"
                                  required
                                  variant="outlined"
                                  value={
                                    additionalGuestDetails[`guest_${guestNum}`]?.lastName?.value
                                  }
                                  error={
                                    additionalGuestDetails[`guest_${guestNum}`].lastName?.error
                                  }
                                  helper={
                                    additionalGuestDetails[`guest_${guestNum}`].lastName?.error
                                      ? "Required"
                                      : ""
                                  }
                                  onChange={(e) => {
                                    handleAdditionalGuestDetailsChange(
                                      `guest_${guestNum}`,
                                      "lastName",
                                      e.currentTarget.value
                                    );
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>

                          <Box mb={2} pr={1}>
                            <TextField
                              fullWidth
                              className={classes.inputField}
                              size="small"
                              label={email.label}
                              type="text"
                              required={guestIdx === 0} // First guest is always required
                              variant="outlined"
                              name={`email-${guestNum}`}
                              disabled={roomIdx === 0 && guestIdx === 0 && isBookerMainGuest}
                              autoComplete="email"
                              value={additionalGuestDetails[`guest_${guestNum}`]?.email?.value}
                              error={additionalGuestDetails[`guest_${guestNum}`].email?.error}
                              helper={
                                additionalGuestDetails[`guest_${guestNum}`].email?.error
                                  ? "Required"
                                  : ""
                              }
                              onChange={(e) => {
                                handleAdditionalGuestDetailsChange(
                                  `guest_${guestNum}`,
                                  "email",
                                  e.currentTarget.value
                                );
                              }}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>

                  {!roomIdx + 1 === additionalGuestInfo.length && (
                    <Divider style={{ margin: "25px 8px 25px 0px " }} />
                  )}
                </Box>
              );
            })}
          </Paper>
        ),
      });
    }

    steps = steps.concat([
      {
        label: "Personalise your stay",
        content: (
          <Paper className={classes.stepContainer}>
            <Box mb={2}>
              <Typography variant="subtitle1" color="textPrimary">
                What is your reason for stay?
              </Typography>
            </Box>
            <Box mb={2}>
              <RadioGroup
                aria-label="reason for stay"
                name="stayPurpose"
                value={reasonForStay}
                onChange={handleReasonForStay}>
                <FormControlLabel
                  className={classNames(classes.checkboxOrRadioContainer)}
                  value="leisure"
                  control={<Radio className={classes.checkbox} color="primary" />}
                  label={
                    <Typography variant="body1" color="textPrimary">
                      Leisure
                    </Typography>
                  }
                />
                <FormControlLabel
                  className={classes.checkboxOrRadioContainer}
                  value="business"
                  control={<Radio className={classes.checkbox} color="primary" />}
                  label={
                    <Typography variant="body1" color="textPrimary">
                      Business
                    </Typography>
                  }
                />
              </RadioGroup>
            </Box>
            <Box mb={2}>
              <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                Special requests?
              </Typography>
              <TextField
                fullWidth
                className={classes.textField}
                variant="outlined"
                multiline
                minRows={2}
                value={bookerDetails.comments}
                onChange={(e) => {
                  handleBookerDetailsChange("comments", e.currentTarget.value);
                }}
              />
            </Box>
          </Paper>
        ),
      },
      {
        label: "Terms & Conditions",
        content: (
          <Paper className={classes.stepContainer}>
            <FormControlLabel
              className={classNames(
                classes.termsBox,
                termsValidationError ? classes.validationFailed : null
              )}
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={termsAccepted}
                  onChange={toggleTermsAcceptance}
                  color="primary"
                />
              }
              label={
                <Typography variant="body1" color="textPrimary">
                  I have read and confirmed the&#8287;
                  <Link
                    href="https://www.molliesmotel.com/privacy-booking-policies/"
                    target="_blank">
                    Terms & conditions
                  </Link>
                </Typography>
              }
            />
            <FormControlLabel
              className={classes.termsBox}
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={bookerDetails.subscribeOffers}
                  onChange={(e) => handleBookerDetailsChange("subscribeOffers", e.target.checked)}
                  color="primary"
                />
              }
              label={
                <Typography variant="body1" color="textPrimary">
                  I would like to receive exclusive offers and updates via email from Mollie&apos;s
                </Typography>
              }
            />
          </Paper>
        ),
      },
      {
        label: "Payment Details",
        content: (
          <Box my={3}>
            <Box mt={1} mb={3}>
              {props.totalToPay > 0 ? (
                searchParams["paymentTerminalId"] ? (
                  // Payment terminal has been passed through so replace the
                  // payment widget with the terminal payment flow
                  <TerminalPaymentWidget
                    onBookButtonClicked={triggerTerminalPayment}
                    currency={"GBP"}
                    amount={props.totalToPay}
                  />
                ) : (
                  <PaymentWidget
                    className={classes.justifyCenter}
                    makeBooking={handleCheckout}
                    setLoading={setBookingInProgress}
                    validateCheckoutForm={validateCheckoutForm}
                    onNotifOpen={props.onNotifOpen}
                    emailAddress={bookerDetails.email}
                    guestData={bookerDetails}
                  />
                )
              ) : (
                // Gift card value covered the total so we can make the booking directly
                <Grid className={classes.makeBookingContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onBookButtonClicked}
                    className={classes.makeBookingButton}>
                    Make Booking
                  </Button>
                </Grid>
              )}
            </Box>
          </Box>
        ),
      },
    ]);

    return steps;
  };

  function getStepsLabels() {
    return checkoutSteps().map((step) => step.label);
  }

  function getStepContent(step) {
    return checkoutSteps().map((step) => step.content)[step];
  }

  const steps = getStepsLabels();

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    // Before advancing validate 'Your Details' Step, checking if user is either logged in or Input is not blank
    if (activeStep === 0) {
      // Booker Details step
      if (!validateInputNotBlank()) return false;
    }

    if (activeStep === 1) {
      // Additional Guest Step
      if (!validateAdditionalGuestDetails()) return false;
    }

    if (activeStep === 3) {
      if (!validateTermsAccepted()) return false;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  function handleShowEditIcon({ currentStep, activeStep }) {
    if (currentStep >= activeStep) {
      return false;
    } else {
      return true;
    }
  }

  const stepTo = (step) => {
    // Before advancing validate
    if (activeStep <= 0 && step > 0) {
      // Handle validation of Booker Details
      if (!validateInputNotBlank()) return false;
    } else if (activeStep <= 1 && step > 1) {
      // Handle validation of Additional Guest Details
      if (!validateAdditionalGuestDetails()) {
        // props.onNotifOpen("Please complete all guest details", {
        //   variant: "error",
        // });
        return false;
      }
    } else if (activeStep <= 3 && step > 3) {
      // Handle validation of terms accepted
      if (!validateTermsAccepted()) return false;
    }

    setActiveStep(step);
  };

  return (
    <div>
      <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      <Grid container className={classes.mainContent}>
        <StepBreadcrumb currentStep={STEPS.PAYMENT.INDEX} />
        <Heading titleText={STEPS.PAYMENT.PAGE_TITLE} />
        <Grid container className={classes.splitContent}>
          <LoadingSpinner loading={loading} />
          <Paper
            className={classNames(classes.checkoutDetailsCard, loading ? classes.hidden : null)}
            elevation={0}>
            {!tabletUpScreen && (
              <BookingSummary
                className={classes.mobileBookingSummary}
                onNotifOpen={props.onNotifOpen}
                onReservationRemoved={handleReservationRemoved}
                canRemoveReservations
                // Disable gift cards for kiosk mode - Its a lot more complicated
                // due to the way we have to trigger and cancel things if anything fails
                showGiftCard={searchParams["paymentTerminalId"] ? false : true}
                color="secondary"
              />
            )}
            <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label} active={activeStep === index}>
                  <StepLabel
                    onClick={() => {
                      stepTo(index);
                    }}>
                    <Box
                      ml={tabletUpScreen ? 2 : 1}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      <Typography variant={tabletUpScreen ? "h4" : "h6"}>{label}</Typography>
                      {handleShowEditIcon({ currentStep: index, activeStep }) && (
                        <Button>
                          <CreateOutlinedIcon className={classes.stepperEditIcon} color="primary" />
                        </Button>
                      )}
                    </Box>
                  </StepLabel>
                  <StepContent
                    hidden={activeStep !== index && !desktopUpScreen}
                    className={classNames(
                      tabletUpScreen ? null : classes.mobileStep,
                      activeStep === steps.length - 1 && !tabletUpScreen
                        ? classes.paymentWidget
                        : null
                    )}>
                    <Box ml={tabletUpScreen ? 2 : 0}>{getStepContent(index)}</Box>

                    <Box>
                      <Grid container justifyContent="flex-end">
                        {activeStep === 2 && (
                          <Button
                            variant="text"
                            className={classes.textButton}
                            color="primary"
                            onClick={handleSkip}>
                            Skip
                          </Button>
                        )}
                        <Box
                          ml={4}
                          hidden={activeStep === steps.length - 1 || !isUserIdentified}
                          style={{}}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleNext}
                            style={{ float: "right" }}>
                            Next Step
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Paper>
          <Grid container className={classes.basket}>
            <Basket
              onNotifOpen={props.onNotifOpen}
              canRemoveReservations
              onReservationRemoved={handleReservationRemoved}
              showGiftCard={!searchParams["paymentTerminalId"]}
            />
          </Grid>
        </Grid>
      </Grid>
      <LoadingDialog
        open={bookingInProgress}
        message={"Connecting to the booking system. Please wait..."}
      />
      <TerminalPaymentInProgressDialog
        paymentData={terminalPaymentData}
        onPaymentSuccess={handleBookingSuccess}
        onPaymentFailed={handleTerminalPaymentFailed}
      />
    </div>
  );
};

Checkout.propTypes = {
  onProfileChange: PropTypes.func,
  onAuthUserChange: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  onNotifOpen: PropTypes.func,
  // Mappped dispatch props
  setPaymentError: PropTypes.func,
  signInUser: PropTypes.func,
  bookingSuccess: PropTypes.func,
  setFirebaseToken: PropTypes.func,
  signOutUser: PropTypes.func,
  // Mapped state props
  propertyId: PropTypes.string,
  roomReservations: PropTypes.array,
  parkingReservations: PropTypes.array,
  giftCard: PropTypes.object,
  totalToPay: PropTypes.number,
  promoCode: PropTypes.string,
  corporateCode: PropTypes.string,
  bookingConfirmationId: PropTypes.string,
  authUser: PropTypes.object,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    propertyId: state.booking.commonSearchParams.propertyId,
    roomReservations: state.booking.roomReservations,
    parkingReservations: state.booking.parkingReservations,
    giftCard: state.booking.giftCard,
    totalToPay: calculateTotalToPay(
      [...state.booking.roomReservations, ...state.booking.parkingReservations],
      state.booking.giftCard
    ),
    promoCode: state.booking.commonSearchParams.promoCode,
    corporateCode: state.booking.commonSearchParams.corporateCode,
    bookingConfirmationId: state.booking.bookingConfirmationId,
    authUser: state.user.authUser,
    profile: state.user.profile,
  };
};

const mapDispatchToProps = {
  setPaymentError,
  signInUser,
  setFirebaseToken,
  signOutUser,
  bookingSuccess,
};

const ConnectedCheckout = connect(mapStateToProps, mapDispatchToProps)(Checkout);
export default ConnectedCheckout;
